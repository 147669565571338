.appointments {
  > h1 {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    @media only screen and (max-width: 991px) {
      font-size: 12px;
    }
  }
  &_row {
    flex-direction: row;
    @media only screen and (max-width: 991px) {
      flex-direction: column-reverse;
      max-width: 85vw !important;
      // margin: auto;
    }
  }
}
.Interpreter {
  font-weight: 400;
  font-size: 10px;
  color: #000000;
  @media only screen and (max-width: 1100px) {
    font-size: 8px;
    > div {
      gap: 5px !important;
    }

    > div > h5 {
      font-size: 10px;
    }
  }
}
.appointment {
  height: 200px;
  border: 1px solid #d1d1d1;
  border-left: none;
  border-right: none;
  background-color: #fefbe1;
  @media only screen and (max-width: 991px) {
    height: 150px;
    width: 80% !important;
    padding-right: 0 !important;
  }

  > h2 {
    font-size: 18px;
    font-weight: bold;

    line-height: 26px;
    text-align: left;
    color: #18324e;
    @media only screen and (max-width: 991px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  > h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    @media only screen and (max-width: 991px) {
      font-size: 10px;
      line-height: 14px;
    }
  }
}
.urls {
  font-size: 10px;
  > a {
    font-size: 10px;
    text-decoration: none;
    color: #18324e;
    @media only screen and (max-width: 1100px) {
      font-size: 8px;
    }
  }
  @media only screen and (max-width: 1100px) {
    font-size: 8px;
  }
}
.date {
  font-size: 16px;
  font-weight: bold;
  color: #18324e;
  @media only screen and (max-width: 991px) {
    font-size: 10px;
    padding-right: 0 !important;
  }
}
.enter {
  font-size: 18px;
  font-weight: 500;
  color: #18324e;
  line-height: 32px;
  text-decoration: none;
  align-items: center;
  gap: 10px;
  @media only screen and (max-width: 991px) {
    font-size: 12px;
    line-height: 20px;
  }
}
.Calendar {
  max-width: -webkit-fill-available;
}
.appointment-box {
  box-shadow: 0px 0px 10px 0px #e1e1e1b2;
  height: 84px;
  width: 95%;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  text-align: center;
  > div > h3 {
    font-size: 12px !important;
    font-weight: 500;
    text-align: left;
    @media only screen and (max-width: 1100px) {
      font-size: 10px !important;
    }
  }
  @media only screen and (max-width: 1100px) {
    height: 100px;
  }
  @media only screen and (max-width: 991px) {
    height: 100px;
    width: 100%;
  }
}
