.custom-btn {
  display: inline-block;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  color: #000;
  transition: 0.2s;
  text-decoration: none;
  border: 1px solid #a1813a;
  @media only screen and (max-width: 991px) {
    font-size: 0.75em;
    padding: 4px 8px;
    line-height: 9px;
  }
  &.outlined-small {
    padding: 0px 0px;
    border: 1px solid #a1813a;
    color: #a1813a;
    font-size: 11px;
  }
  &:hover {
    transition: 0.2s;
  }

  img,
  svg {
    height: 18px;
    @media only screen and (max-width: 991px) {
      height: 14px;
    }
  }

  &.primary {
    background: #a1813a;
    color: #fff;
    padding: 9px 15px;
    > a {
      color: #fff;
    }
    &:hover {
      background: #876a2d;
    }
    @media only screen and (max-width: 991px) {
      padding: 4px 8px;
    }
  }
  &.primary2 {
    background: #18324e;
    color: #fff;
    padding: 9px 24px;
    border: 2px solid #18324e;
    > a {
      color: #fff;
    }
    &:hover {
      background: #18324e;
    }
    @media only screen and (max-width: 991px) {
      padding: 4px 8px;
    }
  }

  &.outlined {
    background: transparent;
    color: #a1813a;
    padding: 9px 24px;
    > a {
      color: #a1813a;
    }
    &:hover {
      background: #937023;
      color: #fff;
    }
    @media only screen and (max-width: 991px) {
      padding: 4px 8px;
    }
  }
  &.outlined2 {
    background: #ffff;
    color: #18324e;
    padding: 9px 24px;
    > a {
      color: #18324e;
    }
    &:hover {
      background: #25466a;
      color: #fff;
    }
    @media only screen and (max-width: 991px) {
      padding: 4px 8px;
    }
  }
  &.border {
    background-color: #ffff;
    color: #18324e;
    border: #18324e 2px solid !important;
    > a {
      color: #18324e;
    }

    &:hover {
      background: #25466a;
      color: #fff;
      > a {
        color: #fff;
      }
    }
    @media only screen and (max-width: 991px) {
      padding: 4px 8px;
    }
  }
}
