.patientList {
  > h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    padding-bottom: 30px;
    @media only screen and (max-width: 991px) {
      font-size: 16px;
      font-weight: 600;
    }
  }
  &_table {
    width: 100%;
    max-width: 90vw;
    box-sizing: border-box;

    > tr {
      width: 100%;
      border-bottom: rgba(247, 247, 247, 1) 2.5px solid;
      border-top: rgba(247, 247, 247, 1) 2.5px solid;
    }
    th,
    td {
      padding: 0.8rem;
      align-items: center;
      @media only screen and (max-width: 991px) {
        padding-top: 0.5rem;
        padding-right: 0.01rem;
        padding-left: 1rem;
      }
    }
    @media only screen and (max-width: 991px) {
      overflow-x: scroll;
      font-size: 12px;
      line-height: 16px;
      box-sizing: unset;
    }
    th {
      @media only screen and (max-width: 991px) {
        font-weight: 600;
      }
    }
    font-size: 16px;
    line-height: 18px;
    &_header {
      background-color: rgba(247, 247, 247, 1);
      height: 48px;
      font-weight: bold;
      color: black;
      font-size: 18px;
      border-radius: 4px 4px 0 0;
      @media only screen and (max-width: 991px) {
        font-size: 12px;
        height: 30px;
      }
    }
    &_row {
      background-color: rgba(252, 252, 252, 1);
      height: 60px;
      @media only screen and (max-width: 991px) {
        height: 40px;
      }
      &_active:hover {
        background-color: rgba(243, 243, 243, 1);
      }
      &_active {
        background-color: rgba(252, 252, 252, 1);
        height: 80px;
        @media only screen and (max-width: 991px) {
          height: 45px;
        }
        > td > img {
          margin: 5px 0.5rem 0 -1.75rem;
          @media only screen and (max-width: 991px) {
            margin: 5px 0.5rem 0 0;
          }
        }
        > td > h5 {
          margin-top: -25px;
          margin-left: 0.5rem;
        }
      }
      > td > img {
        margin: 5px 0.5rem 0 -1.75rem;
        @media only screen and (max-width: 991px) {
          margin: 5px 0.5rem 0 0;
        }
      }
      > td > h5 {
        margin-top: -25px;
        margin-left: 0.5rem;
      }
    }
    &_footer {
      background-color: rgba(247, 247, 247, 1);
      height: 48px;
      color: rgba(161, 129, 58, 1);
      border-radius: 0 0 4px 4px;
    }
  }
}
.add-patient {
  overflow-y: scroll;
  border-radius: 4px;
  padding-right: 2rem !important;
  padding-left: 2rem !important ;
  @media only screen and (max-width: 991px) {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  > div > img {
    width: 55px !important;
  }
  > div > div > img {
    width: 45px !important;
    height: 45px;
  }
  > div > label {
    position: relative;
    &::after {
      position: absolute;
      content: "*";
      margin-left: 5px;
      top: 1px;
      color: #dd0505;
      font-size: 1rem;
    }
  }
  &.info {
    padding-top: 45vh !important;
    @media only screen and (max-width: 991px) {
      padding-top: 5vh !important;
    }
    > h2 {
      font-size: 30px;
      font-weight: 600;
      line-height: 45px;
      text-align: center;
      @media only screen and (max-width: 991px) {
        font-size: 35px;
        line-height: 40px;
      }
      @media only screen and (max-width: 400px) {
        font-size: 25px;
        line-height: 30px;
      }
    }
  }
  &_line {
    border: none;
    border-bottom: 1px solid #d1d1d1;
    width: 100%;
    margin-top: -30px;
    margin-bottom: -40px;
  }

  > h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    @media only screen and (max-width: 991px) {
      font-size: 35px;
      line-height: 40px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 25px;
      line-height: 30px;
    }
  }
  > h3 {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
    @media only screen and (max-width: 991px) {
      font-size: 25px;
      line-height: 30px;
    }
  }
}
.nameOfPatient:hover{
  >h5{
  text-decoration: underline;
  }
}
.Modal1 {
  width: 50vw;
  height: max-content;
  max-height: 90vh;
  background: #ffffff;
  position: absolute;
  top: 5vh;
  left: 25vw;
  @media only screen and (max-width: 991px) {
    width: 70vw;
    left: 15vw;
  }
  @media only screen and (max-width: 400px) {
    width: 90vw;
    left: 5vw;
  }
  overflow-y: scroll;
  border-radius: 4px;
  padding-right: 2rem !important;
  padding-left: 2rem !important ;
  @media only screen and (max-width: 991px) {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  > div > div > div > img {
    width: 45px !important;
    height: 45px;
  }
  > div > div > label {
    position: relative;
    &::after {
      position: absolute;
      content: "*";
      margin-left: 5px;
      top: 1px;
      color: #dd0505;
      font-size: 1rem;
    }
  }

  &_line {
    border: none;
    border-bottom: 1px solid #d1d1d1;
    width: 80%;
    margin-top: -30px;
  }

  > div > h2 {
    font-size: 28px;
    line-height: 45px;
    padding-bottom: 1rem;
    font-weight: 600;
    line-height: 45px;
    text-align: center;
    @media only screen and (max-width: 991px) {
      font-size: 20px;
      line-height: 40px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 25px;
      line-height: 30px;
    }
  }
  > div > h3 {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
    @media only screen and (max-width: 991px) {
      font-size: 25px;
      line-height: 30px;
    }
  }
}
.welcomeModal {
  width: 45vw;
  height: 45vh;
  min-height: max-content;
  background: #ffffff;
  position: absolute;
  top: 17.5vh;
  left: 27.5vw;
  @media only screen and (max-width: 991px) {
    width: 70vw;
    left: 15vw;
  }
  @media only screen and (max-width: 400px) {
    width: 90vw;
    left: 5vw;
  }
}
.Modal2 {
  > h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    text-align: center;
    margin-bottom: 25px;
    @media only screen and (max-width: 991px) {
      font-size: 25px;
      line-height: 40px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 20px;
      line-height: 30px;
    }
  }
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  overflow-y: scroll;
  width: 70vw;
  height: 80vh;
  background: #ffffff;
  position: absolute;
  top: 10vh;
  left: 15vw;
  @media only screen and (max-width: 991px) {
    width: 80vw;
    left: 15vw;
  }
  @media only screen and (max-width: 400px) {
    width: 90vw;
    left: 5vw;
  }
}
.emerjency-box {
  display: grid;
  margin-bottom: 7vh;
  grid-template-columns: 50% 50%;
  @media only screen and (max-width: 991px) {
    grid-template-columns: 100%;
    width: 100% !important;
  }
}
.colaps-emerjency {
  padding-left: 2rem;
  background-color: #fefbe1;
  height: 7vh;
  border-radius: 5px;
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-top: 15px;
  @media only screen and (max-width: 991px) {
    width: 100% !important;
  }
  > span {
    width: 90%;
    text-align: left;
  }
  > div {
    width: 10%;
  }
}
.noncolaps-emerjency {
  width: 85%;
  background-color: #fefbe1;
  border-radius: 5px;
  @media only screen and (max-width: 991px) {
    width: 100% !important;
  }
}
.country {
  position: relative;
  &::before {
    position: absolute;
    content: "*";
    margin-left: -6px;
    top: 1px;
    color: #dd0505;
    font-size: 1rem;
  }
}
.phon-input {
  > input {
    padding: 12px 5px;
    background: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    width: 100%;
  }
  @media only screen and (max-width: 991px) {
    width: 100% !important;
    padding: 1px 5px;
    font-size: 12px;
    margin-bottom: 7px;
  }
}


.detile {
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
  height: max-content;
  @media only screen and (max-width: 991px) {
    padding-left: 0;
    padding-right: 0;
  }
  > div {
    width: 100%;
    justify-content: center;
    display: flex;
    > table {
      width: 100%;
      th {
        background-color: rgba(247, 247, 247, 1);
        text-align: center;
      }
      td {
        background-color: #fcfcfc;
        border-bottom: 1px solid rgba(242, 242, 242, 1);
      }
    }
    
  }
  >button{
    margin-bottom: 50px;
  }
}
.personalTable {
  td {
    font-weight: 700;

    > h6 {
      font-weight: 400;
    }
  }
}
.edit-icon {
  position: absolute;
  right: 7rem;
  cursor: pointer;
  @media only screen and (max-width: 991px) {
    right: 3rem;
  }
}
.services {
  margin-left: 4rem;

  @media only screen and (max-width: 991px) {
    margin-left: 0rem;
  }
  > h5 {
    margin-top: 40px;

    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  .radio {
    margin-left: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    @media only screen and (max-width: 991px) {
      // margin-left: 0rem;
      font-size: 11px;
      font-weight: bold;
    }
  }
  .custom-radio {
    left: -8px;
    top: 6px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 2px solid #18324e;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    @media only screen and (max-width: 991px) {
      // right: 3rem;
      font-size: 10px;
    }
  }

  /* for inner filled circle */
  .custom-radio::after {
    content: "";
    width: 12px;
    height: 12px;
    background: #a1813a;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s;
  }
  .radio-input {
    visibility: hidden;
  }

  .radio-input:checked + span {
    border: 2px solid #a1813a;
  }

  .radio-input:checked + span:after {
    opacity: 1;
  }
}
.info {
  > h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 25px;
    text-align: center;
    @media only screen and (max-width: 991px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
