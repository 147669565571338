.dashboard {
  padding-top: 10%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 10%;
  width: 100%;
  display: grid;
  direction: rtl;
  @media only screen and (max-width: 991px) {
    padding-top: 8vh;
  }
  @media only screen and (min-width: 2000px) {
    padding-left: 20%;
    padding-right: 20%;
  }
  @media only screen and (max-width: 400px) {
    padding-top: 11vh;
  }
  &-profile {
    width: 40%;
    justify-self: left;
    margin-bottom: -5%;
    height: 150px;
    text-align: center;
    align-items: center;
    justify-content: left;
    font-size: 20px;
    font-weight: 700;
    @media only screen and (min-width: 2000px) {
      margin-bottom: 0;
      margin-left: 2%;
    }
    @media only screen and (max-width: 991px) {
      height: 100px;
      width: max-content;
      // margin-right: 35%;
      // @media only screen and (max-width: 320px) {
      //   margin-right: 30%;
      // }
    }
    .emailSpan {
      text-align: left !important;
      @media only screen and (max-width: 991px) {
        margin-top: 15px;
        font-size: 12px;
      }
    }
    .nameSpan {
      text-align: left !important;
      color: #a1813a;
      padding-top: 10px;
      font-size: 16px !important;
      @media only screen and (max-width: 991px) {
        font-size: 10px !important;
      }
    }
    .profileSpan {
      font-size: 14px;
      cursor: pointer;
      @media only screen and (max-width: 991px) {
        font-size: 10px;
      }
    }
    .menuIcon {
      @media only screen and (max-width: 320px) {
        margin-left: 0 !important;
      }
    }
    .mobile-tab {
      position: absolute;
      top: 125px;
      right: 80px;
      width: 50%;
      gap: 5px;

      @media only screen and (min-width: 500px) {
        top: 23vh;
        width: 35%;
        right: 27%;
        @media only screen and (min-width: 770px) {
          top: 18vh;
          width: 30%;
          right: 30%;
        }
      }
    }
    > div > img {
      max-width: 100px;
      @media only screen and (max-width: 991px) {
        max-width: 50px;
      }
    }
  }
}
.divider {
  width: 102%;
  height: 1px;
  background-color: #d1d1d1;
  margin-top: 4%;
  @media only screen and (max-width: 991px) {
    margin-top: 2%;
  }
}
.react-tabs__tab--selected {
  background-color: #f2f2f2;
  border-color: #d1d1d1;
  padding: 18px;
}
.react-tabs__tab {
  font-size: 16px;
  font-weight: 600;
  padding: 18px;
}

.ewqTBN {
  min-width: 250px !important;
}

.notification_svg {
  vertical-align: baseline !important;
}

.notif-wrap {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 68vh;
  overflow-y: scroll;
  width: 40vw;
  max-width: 550px;
  background-color: #f9f9f9;
  border: solid 1px #d1d1d1;
  z-index: 100;
  border-radius: 20px;
  margin-top: 15px;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

.notif-wrap::after {
  content: "";
  position: absolute;
  top: -14px;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 14px solid #d1d1d1;
  z-index: 2;
}

.notif-wrap::before {
  content: "";
  position: absolute;
  top: -12px;
  right: 22px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #f9f9f9;
  z-index: 3;
}

.notif_card{
  width: 96% !important;
  display: flex;
  justify-content: left;
  direction: ltr;
  font-size: 12px;
flex-direction: row;
text-align: left;
gap:5px;
  height: 56px;
  align-items: center;
  background-color: #F9F9F9;
  box-shadow: 0px 0px 10px 0px #E1E1E1B2;

  // box-shadow: rgba(149, 157, 165, 0.2) 3px 8px 24px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding: 5px;
  border-radius: 6px;
}
