.header {
  background-color: #18324e !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 15vh;
  min-height: 12vh;
  z-index: 4;
  overflow-x: hidden;
  overflow-y: hidden;

  @media only screen and (max-width: 991px) {
    background-color: #18324e !important;
    min-height: 3vh;
  }

  a,
  a:hover {
    color: inherit;
    text-decoration: none;
  }

  &__logo {
    @media only screen and (max-width: 991px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & > a > img {
      max-width: 25%;
      @media only screen and (max-width: 991px) {
        max-width: 45%;
        width: 45%;
        margin-bottom: -0.25rem;
        margin-left: 0.25rem;
      }
    }
    button {
      background-color: transparent;
      border: none;
      outline: none;
    }
  }

  &__menu {
    background: transparent;
    border: none;
    color: #fff;
    text-align: right !important;

    .rc-menu {
      &-item {
        cursor: pointer;
        &-active {
          background-color: transparent !important;
          border: none;
          vertical-align: top !important;
          cursor: pointer;
        }
        &-selected {
          background-color: transparent !important;
          color: #a1813a;
        }
        &:hover {
          color: #a1813a;
        }
      }
      &-submenu {
        vertical-align: top;

        &-active {
          background-color: transparent !important;
          border: none;

          &.rc-menu-submenu-active {
            color: #a1813a !important;
          }
          .rc-menu-submenu-title {
            background-color: transparent !important;
          }
        }
        &-selected {
          background-color: transparent !important;
          color: #a1813a;
        }
        &:hover {
          color: #a1813a;
        }
        &-horizontal {
          position: relative;
          &::after {
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            border-right: 2px solid;
            border-bottom: 2px solid;
            top: 40%;
            right: 5px;
            transform: rotate(45deg);
            border-radius: 1px;
          }
        }
      }
    }

    &-submenu {
      z-index: 1001;

      .rc-menu.rc-menu-sub.rc-menu-vertical {
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
        border: none;
        border-radius: 16px;
        overflow: hidden;
        background-color: #fffc;
        li {
          border-bottom: 1px solid #f0f0f0;
          .rc-menu-submenu-title {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #000;

            img {
              margin-left: auto;
              margin-right: 0.5rem;
            }
          }
          a {
            font-size: 14px;
            color: #000;
            text-decoration: none;
          }
        }
      }
    }
  }

  &__drawer {
    background-color: #152c45 !important;
    color: #fff;
    text-align: center;
    top: 4.5rem !important;
    height: calc(100vh - 2.5rem) !important;

    &-item {
      color: #fff;
      font-size: 14px;
      border-bottom: 1px solid rgb(37, 62, 90);
    }
    .rc-menu {
      box-shadow: none !important;
      border: none !important;
      &-sub {
        background-color: transparent !important;
        display: inline-block;
        border-left: 1px solid #fff !important;
        text-align: left;
        margin-left: 4rem;
        margin-top: 0.6rem;
        .rc-menu-item {
          padding-left: 0.5rem !important;
          font-size: 13px !important;
          border: none !important;
        }
      }
      &-item {
        &-active,
        &-selected {
          background-color: transparent !important;
          color: #a1813a !important;
        }
      }
      &-submenu {
        &-title {
          color: #fff;
          font-size: 14px;
          border-bottom: 1px solid rgb(37, 62, 90);
        }
        &-active,
        &-selected {
          background-color: transparent !important;

          .rc-menu-submenu-title {
            background-color: transparent !important;
            color: #a1813a !important;
          }
        }

        &-arrow {
          right: 75px;
          top: 9px;
        }
      }
    }
  }
  &__profile {
    padding-top: 1%;
    padding-right: 2% !important;
    padding-left: 0 !important;
    max-height: 5vh;
    > img {
      max-width: 40px;
      max-height: 5vh;
    }
  }

  .close-btn {
    border: none;
    outline: none;
    color: #fff;
    background-color: transparent;
    padding: 1rem;
    text-align: right;
    width: 100%;
  }
}
.patient-header-wrap > .rc-menu-item-active {
  background-color: inherit !important;
}
