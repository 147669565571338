.footer {
  z-index: 4;
  // background-image: url("../../../assets/images/Map.png");
  background-size: cover;
  &__color {
    background-color: rgba(24, 50, 78, 0.93);
  }
  &__logo {
    img {
      width: 50%;
      @media only screen and (max-width: 991px) {
        width: 100%;
      }
    }
  }

  &__contact {
    &-head {
      font-weight: 500;
      font-size: 16px;
      text-decoration: none;
      color: #ffffff;
      margin-bottom: 2rem;
      display: inline-block;
      // text-decoration: underline;
      @media only screen and (max-width: 991px) {
        font-size: 12px;
        margin-bottom: 16px;
      }
    }
    &-property {
      @media only screen and (max-width: 991px) {
        font-size: 8px;
        line-height: 10px;
        justify-content: start;
      }

      &-value {
        margin-left: 1rem;
        display: flex;
        align-items: start;
        // flex-wrap: wrap;
        @media only screen and (max-width: 991px) {
          margin-left: 8px;
        }
      }
      img {
        margin-top: 0px;
        @media only screen and (max-width: 991px) {
          height: 8px;
          // margin-top: 8px;
        }
      }
    }
    &-socials {
      @media only screen and (max-width: 991px) {
        gap: 10%;
        margin-left: 15%;
        @media only screen and (max-width: 450px) {
          margin-left: 0;
        }
      }
      a {
        margin-right: 0.5rem;
        @media only screen and (max-width: 991px) {
          margin-right: 0px;
        }
        img {
          width: 2rem;

          @media only screen and (max-width: 650px) {
            width: 1.6rem;
          }
        }
      }
    }
  }

  &__menucolumn {
    line-height: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        color: #fff;
      }
    }

    & > a {
      font-size: 18px;
      @media only screen and (max-width: 991px) {
        font-size: 12px;
        line-height: 24px;
      }
    }
    span {
      color: #fff;
      font-size: 18px;
      @media only screen and (max-width: 991px) {
        font-size: 12px;
        line-height: 24px;
      }
    }
    & > div {
      span {
        margin: 0.5rem 0;
        display: block;
      }
      div {
        display: flex;
        flex-direction: column;
        padding-left: 0.8rem;
        border-left: 1px solid aliceblue;
        line-height: 19px;
        gap: 1.1rem;

        a {
          font-size: 14px;
          @media only screen and (max-width: 991px) {
            font-size: 10px;
            line-height: 14px;
          }
        }
      }
    }
  }

  &__subscribe-title {
    display: block;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    @media only screen and (max-width: 991px) {
      font-size: 11px;
      margin-top: 1rem;
    }
  }

  &__socket {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    text-align: center;
    padding: 24px 16px 32px 16px;
    border-top: 1px solid #fff;
    @media only screen and (max-width: 991px) {
      font-size: 8px;
      padding: 12px 16px 12px 16px;
    }
  }
}
