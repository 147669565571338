// @use postcss-nested
.services1 {
  margin-top: 3rem;
  @media only screen and (max-width: 991px) {
    margin-top: 0.2rem;
  }
  > button {
    border: none;
    background-color: transparent;
    color: black;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    padding-bottom: 10px;
    @media only screen and (max-width: 991px) {
      font-size: 12px;
    }
  }
  > button:hover {
    border-bottom: 1.7px solid rgba(161, 129, 58, 1);
  }
  .active {
    font-weight: bold;
    border-bottom: 1.7px solid rgba(161, 129, 58, 1);
  }
}
.page-desc{
  >p{
    font-size:18px;
    line-height: 24px;
    >strong{
      font-size: 14px;
      font-weight: 600;
    }
    
  }
  >h1{
    font-size:25px;
    line-height: 30px;
  }
  >h2{
    font-size: 22px;
    line-height: 27px;
  }
 
}
.servic-card {
  align-self: center;
  min-height: 400px;
  max-height: max-content;
  max-width: 22vw;
  background-color: #ffff;
  box-shadow: 0px 4px 10px 0px rgba(225, 225, 225, 0.4);
  border-radius: 10px;
  @media only screen and (max-width: 991px) {
    max-width: 100%;
    width: 100%;
    min-height: 300px;
    align-self: start;
  }
  @media only screen and (min-width: 2001px) {
    max-width: 12vw;
  }
  h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    @media only screen and (max-width: 991px) {
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
    }
  }
  h4 {
    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    @media only screen and (max-width: 991px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  h3 {
    color: rgba(158, 158, 158, 1);
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    @media only screen and (max-width: 991px) {
      font-size: 11px;
    }
  }
}
.react-multi-carousel-list {
  max-width: 80vw;
  height: 480px;
  position: unset !important;
  margin: 0 -1rem 0 2rem;
  @media only screen and (min-width: 2000px) {
    max-width: 50vw;
    margin: 0 -3rem 0 4rem;
  }
}
.react-multiple-carousel__arrow--right {
  right: calc(8% + 1px) !important;
  @media only screen and (min-width: 1600px) {
    right: calc(9% + 1px) !important;
  }
  @media only screen and (min-width: 2000px) {
    right: calc(24% + 1px) !important;
  }
  @media only screen and (max-width: 991px) {
    right: calc(7% + 1px) !important;
  }
}
.react-multiple-carousel__arrow--left {
  left: calc(8% + 1px) !important;
  @media only screen and (min-width: 2000px) {
    left: calc(19% + 1px) !important;
  }
  @media only screen and (max-width: 991px) {
    left: calc(4% + 1px) !important;
  }
}
.react-multi-carousel-dot-list {
  position: relative !important;
}
.bar {
  background: rgba(240, 240, 240, 0.53);
  border: 1px solid rgba(240, 240, 240, 0.53);
  border-radius: 30px;
  width: 100%;
  height: 12px;
  .complate {
    height: 12px;
    width: 57%;
    border-radius: 30px;
    background: rgba(24, 50, 78, 0.4);
  }
}
.service-page {
  padding-right: 20px;
  > h2 {
    font-size: 30px;
    font-weight: 700;
  }
  > h3 {
    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    font-weight: 700;
  }
  > h4 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  > p {
    margin-top: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
}
.v-line {
  height: 27px;
  width: 1px;
  background-color: rgba(158, 158, 158, 0.53);
}

.desc-box {
  background: rgba(250, 250, 250, 0.5);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  margin: -10px -0.3rem 15px 0.7rem;
  width: 103%;
  overflow-x: hidden;

  >p{
    line-height: 20px;
    text-align: justify;
  }

  @media only screen and (max-width: 991px) {
    margin: -15px 5px 10px 20px;
    // width: 78%;
    font-size: 10px;
    overflow-y: scroll; /* Allows vertical scrolling */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;
  }
}
.step-box {
  box-shadow: 0px 0px 10px 0px rgba(225, 225, 225, 0.7);
  border-radius: 10px;
  margin: 8px 25px 8px 10px;
  align-items: center;
  padding-left: 25px;
  .step-title {
    color: rgba(24, 50, 78, 1);
    font-weight: 700;
    font-size: 16px;
    margin-top: -10px;
    @media only screen and (max-width: 991px) {
      font-size: 15px;
      margin-top: 3%;
    }
  }
  @media only screen and (max-width: 991px) {
    box-shadow: none;
  }
}
.title {
  color: rgba(24, 50, 78, 1);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  @media only screen and (max-width: 991px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 14px;
  }
}
.react-multi-carousel-dot--active button {
  background: rgba(161, 129, 58, 1) !important;
}
.react-multi-carousel-dot button {
  border-color: transparent !important;
  background: rgba(217, 217, 217, 1);
}
.reply-box {
  position: absolute;
  left: 61%;
  border-radius: 4px;
  border: 1px solid var(--light-grey, #d1d1d1);
  background: #fff;
  z-index: 4;
  @media only screen and (max-width: 991px) {
    left: 30%;
  }

  h3 {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
  h4 {
    color: #2e6cdf;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 123.077% */
  }
  p {
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #4c4c4c;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
  h5 {
    color: #9e9e9e;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  span {
    cursor: pointer;
    color: #000;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 145.455% */
  }
}

.fixButton {
  position: absolute;
  bottom: 20px;
  width: 70% !important;
  @media only screen and (max-width: 991px) {
    margin-top: 10%;
    bottom: 10px;
    margin-left: 5%;
  }
  @media only screen and (min-width: 1991px) {
    width: 75% !important;
  }
}

.h-line {
  width: 100%;
  height: 1px;
  background-color: rgba(158, 158, 158, 0.53);
  margin-top: 7px;
  margin-bottom: 15px;
}
.react-multi-carousel-list {
  @media only screen and (max-width: 991px) {
    height: 325px;
    width: 70vw;
    margin: 2% 6%;
  }
}
.documentModal {
  width: 50vw;
  height: 80vh;
  background: #ffffff;
  position: absolute;
  top: 15vh;
  left: 30vw;
  z-index: 5 !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  overflow-y: scroll;
}

.step-button {
  text-align: center;
  padding: 20px 10px 20px 10px;
  border-radius: 5px;
  margin: 1px 1px 1px 1px;
  background-color: white;
  border: none;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  position: relative;
  max-height: 65px;

  color: rgba(24, 50, 78, 1);
  @media only screen and (max-width: 991px) {
    padding: 8px 5px;
    font-size: 9px;

    // max-height: 35px;
    height: 35px !important;
  }
  .desible {
    color: rgba(24, 50, 78, 0.3) !important;
  }
}

.step-button-active {
  text-align: center;
  padding: 20px 10px 20px 10px;
  border-radius: 5px;
  margin: 1px 1px 1px 1px;
  background-color: white;
  border: none;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  position: relative;
  max-height: 65px;

  color: rgba(24, 50, 78, 1);
  @media only screen and (max-width: 991px) {
    padding: 8px 5px;
    font-size: 11px;
    // max-height: 35px;
    height: 35px !important;
  }
  .desible {
    color: rgba(24, 50, 78, 0.3) !important;
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 10%;
    height: 0;
    border-top: solid 10px var(--triangle-color);
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
}

:root {
  --send-bg: #e0ffee;
  --send-color: black;
  --receive-bg: #e3f3fb;
  --receive-text: black;
  --page-background: white;
}

.body {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  background-color: var(--page-background);
  p {
    max-width: 500px;
    word-wrap: break-word;
    margin-bottom: 8px;
    line-height: 16px;
    position: relative;
    padding: 10px 20px;
    border-radius: 25px;
    @media only screen and (max-width: 991px) {
      max-width: 200px;
    }
  }
  span {
    font-size: 10px;
    display: block;
    text-align: end;
  }
}

.send {
  color: var(--send-color);
  background: linear-gradient(to right, #bbfeda, #e0ffee);
  align-self: flex-end;
  @media only screen and (max-width: 991px) {
    font-size: 11px;
  }
  &:before {
    right: -7px;
    width: 20px;
    background-color: var(--send-bg);
    border-bottom-left-radius: 16px 14px;
  }

  &:after {
    right: -26px;
    width: 26px;
    background-color: var(--page-background);
    border-bottom-left-radius: 10px;
  }
  &:hover {
    background: linear-gradient(to right, #a9f6cd, #c4f8db);
  }
}
.receive {
  background: linear-gradient(to right, #e3f3fb, #bbe7fe);
  color: black;
  align-self: flex-start;
  @media only screen and (max-width: 991px) {
    font-size: 11px;
  }
  &:before {
    left: -7px;
    width: 20px;
    background-color: var(--receive-bg);
    border-bottom-right-radius: 16px 14px;
  }

  &:after {
    left: -26px;
    width: 26px;
    background-color: var(--page-background);
    border-bottom-right-radius: 10px;
  }
}
.message-box {
  flex: 0 1 40px;
  width: 100%;
  border: #9e9e9e solid 0.5px;
  padding: 10px 0px 0px 10px;
  position: relative;
  border-radius: 20px;
  margin-top: 40px;
  z-index: 1;
  @media only screen and (max-width: 991px) {
    margin: 5px;
    padding: 8px 0 0 8px;
  }

  & .message-input {
    border: none;
    outline: none !important;
    resize: none;
    font-size: 14px;
    margin: 0;
    padding-right: 20px;
    width: 95%;
    // overflow: scroll;
    @media only screen and (max-width: 991px) {
      font-size: 12px;
      resize: none; /* Optional: Prevents manual resizing */
      overflow-y: scroll; /* Allows vertical scrolling */
      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none;
    }
  }

  textarea:focus:-webkit-placeholder {
    color: transparent;
  }

  & .message-submit {
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 10px;
    color: #fff;
    border: none;
    background: white;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 1;
    padding: 6px 10px;
    border-radius: 10px;
    outline: none !important;
    transition: background 0.2s ease;

    //   &:hover {
    //     background: #1D7745;
    //   }
  }
}
.rc-menu-item-selected {
  background-color: white !important;
  transform: translateZ(0);
}



.message-input::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
