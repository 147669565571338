.forms {
  height: 100%;
  padding-top: 7%;
}
.form-card {
  width: 95%;
  height: 103px;
  margin: 0% 2.5% 0 2.5%;
  text-align: center;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  align-items: center;
  background-color: #ffff;
  box-shadow: 0px 0px 10px rgba(225, 225, 225, 0.7);
  @media only screen and (max-width: 750px) {
    height: 100px;
  }

  &__title {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    padding-left: 5px;
    padding-right: 4px;
    @media only screen and (max-width: 991px) {
      width: 40%;
      padding-left: 10px;
    }
    > h5 {
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      @media only screen and (max-width: 750px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    > h6 {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      color: #18324e;
      @media only screen and (max-width: 750px) {
        font-size: 11px;
        line-height: 14px;
      }
    }
  }
  &__divider {
    height: 90px;
    width: 1px;
    margin-left: 3px;
    background-color: rgba(225, 225, 225, 1);
    @media only screen and (max-width: 750px) {
      height: 80px;
    }
  }
  &__desc {
    display: flex;
    flex-direction: column;
    width: 40%;
    padding-left: 6%;
    padding-right: 1%;
    font-size: 16;
    line-height: 20px;
    @media only screen and (max-width: 991px) {
      padding-left: 6%;
      font-size: 12px;
      @media only screen and (max-width: 550px) {
        padding-left: 4%;
        font-size: 10px;
      }
    }
  }
  & > a > button {
    border: none;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    background-color: rgba(24, 50, 78, 1);
    color: #ffff;
    font-size: 16px;
    text-align: center !important;
    margin-right: 2%;
    margin-left: 1%;
    @media only screen and (max-width: 750px) {
      font-size: 10px;
      margin-top: 8px;
      height: 70%;
    }
  }
  > a {
    color: #ffff;
    text-decoration: none;
    width: 15%;
    height: 60%;
  }
  &__button {
    border: none;
    border-radius: 4px;
    width: 11%;
    height: 20%;
    background-color: rgba(24, 50, 78, 1);
    color: #ffff;
    font-size: 16px;
    text-align: center;
    margin-right: 2%;
    margin-left: 1%;
    @media only screen and (max-width: 750px) {
      font-size: 10px;
    }
  }
}
.accordion{
  width: 95%;
  
}

.accordion__button {
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  align-items: center;
  text-align: center !important;
  box-shadow: 0px 0px 10px rgba(225, 225, 225, 0.7);
  background-color: #ffff !important;
  @media only screen and (max-width: 750px) {
    height: 80px;
  }
}
.accordion__panel {
  height: 250px;
  overflow-y: scroll;
  padding-left: 3rem;
  padding-right: 3rem;
  align-items: center;
  justify-content: center;
  background: #f3f5f8;
  box-shadow: 0px 0px 10px rgba(225, 225, 225, 0.7);
  @media only screen and (max-width: 750px) {
    height: 180px;
    padding-left: 0.75rem !important;
    padding-right: 0.5rem !important;
  }
}
.accordion__button:before {
  display: inline-block;
  content: url("./expand.svg") !important;
  margin-right: 12px;
  margin-bottom: 10px;
  border-bottom: none !important;
  border-right: none !important;
  transform: none !important;
}
.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  transform: none !important;
  content: url("./notExpand.svg") !important;
}
