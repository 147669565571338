.profile-info {
  padding-top: 12%;
  padding-left: 5%;
  > div > h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    padding-bottom: 30px;
  }
  @media only screen and (max-width: 991px) {
    padding-top: 22%;
    padding-right: 5%;
  }
}
.DatePicker__input {
  width: 100% !important;
  padding: 18px 16px !important;
  border-radius: 4px;
  font-size: 16px !important;
  @media only screen and (max-width: 991px) {
    padding: 8px 5px !important;
    font-size: 11px !important;
  }
}
.chang-box {
  border-radius: 4px;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important ;
  width: 100%;
  @media only screen and (max-width: 1100px) {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  > h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    @media only screen and (max-width: 991px) {
      font-size: 25px;
      line-height: 40px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 18px;
      line-height: 30px;
    }
  }
  > h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
    @media only screen and (max-width: 991px) {
      font-size: 10px;
      line-height: 16px;
    }
  }
}

.Modal {
  width: 40vw;
  height: 80vh;
  background: #ffffff;
  position: absolute;
  top: 10vh;
  left: 30vw;
  z-index: 5 !important;
  overflow-x: scroll;
  @media only screen and (max-width: 991px) {
    width: 70vw;
    left: 15vw;
  }
  @media only screen and (max-width: 400px) {
    width: 90vw;
    left: 5vw;
  }
}
.DatePicker {
  z-index: 2 !important;
}
.Calendar__yearSelector{

  overflow-y: scroll;
}
::-webkit-scrollbar{
  display: block !important;
}


