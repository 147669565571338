.sign-up {
  align-items: center;

  &-header {
    height: 100px;
    width: 30%;
    & > img {
      width: 10%;
      @media only screen and (min-width: 2000px) {
        max-width: 40px;
       }
    }
    & > a {
      width: 75%;
      @media only screen and (min-width: 2000px) {
        max-width: 350px;
       }
      & > img {
        padding-top: 5%;
        width: 100%;
      }
    }
    @media only screen and (max-width: 991px) {
      height: 20vh;
      width: 65%;
    }
    @media only screen and (max-width: 400px) {
    
      width: 75%;
    }
  }
  &-box {
    width: 40vw;
    height: 54vh;
    position: absolute;
    top: 20vh;
    left: 30vw;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(225, 225, 225, 0.6);
    border-radius: 4px;
    box-sizing: border-box;
    padding-right: 3rem;
    padding-left: 3rem;
    padding-bottom: 2rem;

    @media only screen and (max-width: 991px) {
      width: 90vw;
      left: 5vw;
      height: 35vh;
      padding: 1%;
      border: double 1px transparent;
      border-radius: 10px;
      background-image: linear-gradient(white, white),
        linear-gradient(to bottom,#d0c0a5, #f1ece4) ;
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

     
    }
    @media only screen and (max-width: 400px) {
      height: 50vh;
    }

    > h2 {
      font-size: 40px;
      font-weight: 700;
      line-height: 45px;
      padding-top: 40px;
      text-align: center;
      @media only screen and (max-width: 991px) {
        font-size: 28px;
        line-height: 32px;
      }
      @media only screen and (max-width: 400px) {
        font-size: 22px;
        line-height: 28px;
      }
    }
    > h3 {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      text-align: center;
      @media only screen and (max-width: 991px) {
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
  @media only screen and (max-width: 991px) {
   align-items: center;
   display: flex;
  }
 
}
.disable {
  color: gray;
}
.enable {
  color: #a1813a;
  cursor: pointer;
}
.signupinput
{
  margin-right: 1rem;
  margin-left: 0.5rem;
}